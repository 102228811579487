@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


 body {
  font-family: "Space Mono", monospace;
 }

*{
  margin: 0;
  padding: 0;
}
.css-1ponq19-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  background-color: rgb(54, 53, 53);
  color: white;
}

.css-16eb7kl-MuiInputBase-root-MuiOutlinedInput-root{
 height: 35px;
}

.boxShadow{
  box-shadow: rgba(123, 123, 226, 0.25) 0px 30px 60px -12px inset, rgba(90, 181, 230, 0.3) 0px 18px 36px -18px inset, rgba(58, 183, 255, 0.3) 0px 18px 200px -18px inset;
}

.fav {
  background-color: #17120e;
}

.fsv2{
  background-color: #1a120e;
}



@layer utilities {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }
     /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
    }
  }





  .navbar {
    overflow: hidden;
    background-color: #333;
    position: fixed;
    top: 75px;
    width: 100%;
  }
  
  .navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .navbar a:hover {
    background: #ddd;
    color: black;
  }
  
  .main {
    padding: 16px;
    
    height: 1500px; /* Used in this example to enable scrolling */
  }
